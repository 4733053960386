.h1 {
 
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    margin: 0;
  }
  .h2 {
   
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 40px;
  }
  .h3 {
   
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  .h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }
  .h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
  
  .h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
  .primaryGradient {
    background: linear-gradient(90deg, #90baff 0%, #ec5863 100%);
  }
  .redMonoGradient {
    background: linear-gradient(90deg, #f07982 0%, #bd464f 100%);
  }
  .blueMonoGradient {
    background: linear-gradient(90deg, #a6c8ff 0%, #617fb0 100%);
  }
  
  .white {
    color: #ffffff;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    gap: 15px;
    width: 49%;
    min-height: 370px;
    background: #ffffff;
    margin-bottom: 20px;
    border: 2px solid #ec5863;
    border-radius: 20px;
    transition: box-shadow 0.3s ease;
    position: relative;
  
  }
  .ExclusiveContainer {
    margin: 0px;
    margin-bottom: 20px;
  }
  .container:hover {
    box-shadow: 0px 9px 62px 0px rgba(0, 0, 0, 0.14);
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
  }
  .cardLogo {
    width: 48px;
    height: 48px;
    border-radius: 50px;
  }
  .cardLogoCoins {
    width: 48px;
    height: 48px;
    margin-right: 1rem;
    border-radius: 50px;
  }
  
  .headRightChild {
    display: flex;
    align-items: center;
  }
  .titleName {
   
    color: #ec5863;
    padding-right: 10px;
  }
  .titleDefault {
   
    color: black;
  }
  .price {
    display: flex;
    gap: 10px;
  }
  .newPrice {
   
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #ec5863;
  }
  .oldPrice {
   
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #71717a;
    text-decoration-line: line-through;
  }
  .text {
    width: 100%;
  }
  .textSpan {
    color: #27272a;
   
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  
  .list {
    display: flex;
  }
  
  .listLi:before {
    content: "\2713\0020";
    color: #52525b;
  }
  
  .listUl {
    list-style-type: none;
    padding: 0;
   
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #52525b;
    margin: 0;
  }
  
  .tag {
    background: linear-gradient(90deg, #8ab1ff 0%, #ff616d 100%);
    margin-right: -32px;
    width: 30.64%;
    height: 36px;
   
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .ExclusiveTag {
    background: linear-gradient(90deg, #18b6ff 0%, #3de184 100%);
  }
  
  .ribbonBefore {
    transform: rotate(90deg);
    margin-left: -10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 22px solid white;
  }
  
  .tail {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 8px;
    bottom: 12px;
    right: 23px;
    position: absolute;
  }
  .tailData {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
  .viewDetails {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #27272a;
  }
  
  
  .buyNow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px 12px 10px 16px;
    gap: 8px;
    cursor: pointer;
    width: 112px;
    height: 40px;
    background: #ec5863;
    border-radius: 8px;
   
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    user-select: none;
  }
  .pdfBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px 12px 10px 16px;
    gap: 8px;
    cursor: pointer;
    width: 112px;
    height: 40px;
    background: #fff;
    border-radius: 8px;
   
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    user-select: none;
  }
  .pdfBtn:hover {
    text-decoration: underline; /* Apply underline on hover */
  }
  .gradientBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px 12px 10px 16px;
    gap: 8px;
    cursor: pointer;
    width: 112px;
    height: 40px;
    background: linear-gradient(90deg, #90baff 0%, #ec5863 100%);
    border-radius: 8px;
   
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    user-select: none;
  }
  .gradientBtn:hover {
    text-decoration: underline; /* Apply underline on hover */
  }
  .buyedNow {
    cursor: not-allowed !important;
    background: #ec5862b6;
  }
  .purchasedDisabled {
    cursor: not-allowed !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 10px 12px 10px 16px;
    gap: 8px;
    width: 112px;
    height: 40px;
    background: gray;
    border-radius: 8px;
   
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
  
  .normalContainer {
    width: 23.66% !important;
    border: 2px solid #e4e4e7 !important;
  }
  .displayNone {
    display: none !important;
  }
  .normalTitle {
    padding: 0 !important;
  }
  .normalTail {
    position: absolute;
    bottom: 24px;
  }
  @media only screen and (max-width: 1220px) {
    .container {
      width: 100%;
      height: 100%;
    }
    .normalContainer {
      width: 100% !important;
      height: 335px !important;
    }
  }
  
  @media (max-width: 420px) {
    .ExclusiveContainer {
      margin: 0px;
      margin-bottom: 20px;
    }
    .tail {
      margin: 20px;
      position: relative;
      flex-direction: column-reverse;
    }
    .buyNow {
      align-items: center;
      width: 100%;
    }
    .pdfBtn {
      align-items: center;
      width: 100%;
    }
  
    .gradientBtn {
      align-items: center;
      width: 100%;
    }
  }
  