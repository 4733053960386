@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #EC5863; 
}
.custom-input-class {
  @apply  bg-gray-50 bg-opacity-50  rounded border border-gray-300 focus:border-red-500 focus:bg-white focus:ring-2 focus:ring-red-200 text-base  font-normal outline-none my-1 py-0 px-3 leading-8 transition-colors duration-200 ease-in-out 
}
body {
  font-family: "Rubik";
  background-color: white;
}


code {
  font-family: "Rubik",
}

.containerbg {
  background: url("/public/bg.svg") fixed center;
  background-repeat: repeat;
}
 .ant-table.ant-table-small .ant-table-cell {
  padding: 4px 4px !important; /* Adjust the padding */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}