.PermissionsNav
{
    background-color: #FF4C5A !important;   
}
.PermissionsNav h1
{
    background-color: #FF4C5A;
    color: white;
    font-family: 'Rubik';
    font-weight: 400;
}
.LogoPremed
{
    mix-blend-mode:inherit /* Choose the desired blending mode */
}
.Center-All
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}
.Permissions-Div
{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 10px;
    justify-content: space-between;
    margin-top: 2rem;
}
.Button-Dash
{
    margin-right: 3rem !important;
    margin-bottom: 2rem;
}
.Button-Dash button
{
    width: 100%;
    height: 100%;
    background-color: #FF4C5A;
    border: 1px solid white;
    color: white;
    font-family: 'Rubik';
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.Button-Dash button:hover
{
    background-color: white;
    color: #FF4C5A;
    border: 1px solid #FF4C5A;
}
.Button-Dash button:active
{
    background-color: #FF4C5A;
    color: white;
    border: 1px solid white;
}
.Button-Dash button:focus
{
    outline: none;
}
.Button-Dash button:disabled
{
    background-color: black;
    color: white;
    border: 1px solid white;
    cursor: not-allowed;
}
.smth-para
{
    font-size: 1rem;
    font-weight: 700;
    color: black;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    background-color: #FF4C5A;
    padding: 10px;
    border-radius: 5px;
    width:fit-content;
    margin-left: auto;
    margin-right: auto;
    color: white;

}

.demovideo
{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.smth-img
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.75rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: #FAFAFA;

}

.smth-img img{
    width: 7rem;
    object-fit: contain;
    border-radius: 5px;
}

.smth-img button{
    width: 7rem;
    background-color: #FF4C5A;
    border: 1px solid white;
    color: white;
    font-family: 'Rubik';
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}




