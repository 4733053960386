.imageSelectedAlert {
  color: rgb(24, 24, 24);
  padding: 0.4rem;

  border-left: 3px solid #4caf50;
  border-radius: 2px;
  background-color: #95ff98be;
}
.careers-formbold-mb-5 {
  margin-bottom: 20px;
}
.careers-formbold-pt-3 {
  padding-top: 12px;
}
.mmy-6 {
  margin-bottom: 2rem;
}
.careers-formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.careers-formbold-form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 100%;
  background: white;
}
.careers-formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}
.careers-formbold-form-label-2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.careers-formbold-form-input {
  width: 100%;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: white;
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  outline: none;
  resize: none;
}
.careers-formbold-form-input:focus {
  border-color: #ec5863;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.careers-formbold-btn {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 14px 32px;
  border: none;
  font-weight: 600;
  background-color: #ec5863;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.careers-formbold-btn.careers-linkButton:hover {
  color: white;
  text-decoration: none;
}

.careers-formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.careers-formbold--mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}
.careers-formbold-px-3 {
  padding-left: 12px;
  padding-right: 12px;
}
.careers-flex {
  display: flex;
}
.careers-flex-wrap {
  flex-wrap: wrap;
}
.careers-w-full {
  width: 100%;
}

.careers-formbold-file-input input {
  opacity: 0;
  position: absolute;
}

.careers-formbold-file-input label {
  position: relative;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;
  text-align: center;
}

.careers-formbold-or {
  font-weight: 500;
  font-size: 16px;
  color: #6b7280;
  display: block;
  margin-bottom: 8px;
}
.careers-formbold-browse {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.careers-formbold-file-list {
  border-radius: 6px;
  background: #f5f7fb;
  padding: 16px 32px;
}

.careers-formbold-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.careers-formbold-file-item button {
  color: #07074d;
  border: none;
  background: transparent;
  cursor: pointer;
}

.careers-formbold-file-name {
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  padding-right: 12px;
}
.careers-formbold-progress-bar {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background: #e2e5ef;
}

.careers-formbold-progress {
  position: absolute;
  width: 75%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ec5863;
  border-radius: 8px;
}
.careers-manageJobContainer {
  padding: 2rem;
  width: 100%;
  max-width: 100vw;
}
.careers-departmentsDropDown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  color: #333;
}

.careers-departmentsDropDown option {
  background-color: #fff;
  color: #333;
  font-size: 16px;
  padding: 8px;
}

.careers-departmentsDropDown::-ms-expand {
  display: none;
}

.careers-departmentsDropDown + div > div {
  display: none;
}

.careers-departmentsDropDown + div {
  position: relative;
}

.careers-departmentsDropDown:focus + div > div {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 2;
}
.careers-styled-table {
  border-collapse: collapse;
  margin: 25px auto;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  width: 100%;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.imageSnap img {
  width: 100%;
  max-width: 20rem;
}
.lableTray {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconSnap,
.imageSnap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.careers-styled-table thead tr {
  background-color: #ec5863;
  color: #ffffff;

  text-align: left;
}
.careers-styled-table thead tr td {
  font-weight: 600 !important;
}
.careers-styled-table th,
.careers-styled-table td {
  padding: 12px 15px;
  font-weight: 500;
}
.careers-dimensionError {
  color: #4caf50;
  font-weight: 500;
}
.careers-dimensionError.active {
  color: #d32f2f;
  font-weight: bold;
  padding: 0.4rem;
  border-left: 3px solid red;
  border-radius: 2px;
  background-color: rgb(255, 197, 197);
}

.careers-styled-table th,
.careers-styled-table td {
  padding: 12px 15px;
}
.careers-formLink {
  max-width: 18rem;
  overflow: hidden;
}
.careers-linkButton {
  display: inline-block;
  text-decoration: none;
  padding: 9px;
}

.careers-statusToogleTd {
  display: flex;
  align-items: center;
  justify-content: center;
}
.careers-styled-table .status {
  text-transform: capitalize;
}

.careers-styled-table tbody tr.active-row .status {
  font-weight: bold;
  color: #ec5863;
}

.careers-filterContainer {
  display: flex;
  align-items: center;
  justify-content: start;
}
.careers-filterContainer select {
  margin-right: 0.6rem;
}
.careers-filterContainer button {
  text-align: center;
  font-size: 16px;
  border-radius: 6px;
  padding: 10px 32px;
  margin-left: auto;
  border: none;
  font-weight: 600;
  background-color: #ec5863;
  color: white;
  cursor: pointer;
}

@media (min-width: 540px) {
  .sm\:w-half {
    width: 50%;
  }
}

.careers-jobBgSnapShot {
  width: 10rem;
}
.careers-jobIconSnapShot {
  width: 1.5rem;
}

/* Styles for the Publish button */
.careers-publish-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

/* Hover effect for the Publish button */
.careers-publish-button:hover {
  background-color: #45a049;
}

/* Styles for the Unpublish button */
.careers-unpublish-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

/* Hover effect for the Unpublish button */
.careers-unpublish-button:hover {
  background-color: #d32f2f;
}
.careers-publish-toggle {
  display: none;
}

.careers-toggle-label {
  cursor: pointer;
  width: 40px;
  height: 20px;
  background: grey;
  display: block;
  border-radius: 10px;
  position: relative;
}

.careers-toggle-label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.careers-publish-toggle:checked + .careers-toggle-label {
  background: #bada55;
}

.careers-publish-toggle:checked + .careers-toggle-label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.careers-toggle-label:active:after {
  width: 22px;
  height: 22px;
}

.careers-openFormbutton {
  background-color: initial;
  background: rgb(210, 208, 208);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue", Arial,
    sans-serif;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 9rem;
  z-index: 9;
  border: 0;
}

.careers-openFormbutton:hover {
  background: #00bd68;
}

.careers-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

.careers-modal-content {
  max-width: 25rem;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.careers-modal h2 {
  margin: 0;
}

.careers-modal p {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 10px;
}

.careers-modal-buttons {
  margin-top: 20px;
}

.careers-modal-btn {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-btn {
  background-color: #3498db;
  color: white;
}

.cancel-btn {
  background-color: #e74c3c;
  color: white;
}

.careers-text-center {
  text-align: center;
}
