.options {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
    bottom: 0;
    left: 0;
    position: fixed;
    box-shadow: 1px 0px 5px 5px rgba(222, 222, 222, 0.75);
    -webkit-box-shadow: 1px 0px 5px 5px rgba(222, 222, 222, 0.75);
    -moz-box-shadow: 1px 0px 5px 5px rgba(222, 222, 222, 0.75);
    padding: 1rem;
    z-index: 9999;
}

.video {
    position: fixed;
    bottom: 6rem;
    right: 2rem;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    object-fit: cover;
    border: 5px solid rgb(255, 118, 127);
}

.penSettings {
    display: flex;
    justify-content: center;
    align-items: center;
}

.penSettings h5 {
    font-size: 0.9rem;
    margin-top: 0.6rem;
    margin-right: 0.5rem;
}

.penSettings input {
    margin-right: 0.5rem;
}

.optionsBtn {
    /* width: 5rem; */
    background-color: #EC5863;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 0.4rem;
    font-size: 0.9rem;
    cursor: pointer;
    margin-left: 0.5rem;

}

.FullDiv {
    margin-top: 1rem;
    margin-left: 1rem;
    width: 40%;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
}

.DoubtDiv {
    box-shadow: 0px 8.377778053283691px 35.60555648803711px 0px #00000026;
    background-color: white;
    border: 1px solid rgb(204, 204, 204);
    padding: 0.8rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.DoubtDiv h6 span {
    font-weight: bold;
    color: #EC5863;
}

.DoubtDiv img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
#timer
{
    margin-top: 1rem;
    margin-right: 1rem;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    z-index: 9999;
    background-color: white;
    font-size: 2rem;
    font-weight: bold;
    border: 1px solid gray;
    border-radius: 5px;
}
@media (max-width: 767px) {
    .options {
        display: grid;
        grid-template-columns: 100%;
    }
    .OneMoreDiv
    {
        display: flex;
        justify-content: center;
        margin-bottom: 0.5rem;
        gap: 4 !important;
    }
    .video {
        position: fixed;
        top : 0.5rem;
        left: 2rem;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        object-fit: cover;
        border: 5px solid rgb(255, 118, 127);
    }
    
}
.Modal
{
    position: fixed;
    z-index: 100000;
}

.page-container
{
    background-position: url('./Background.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

TableCell
{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}