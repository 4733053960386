.mainContainer {
  width: auto;
  margin-left: 100px;
  margin-right: 100px;
}

.navbarText {
  padding: 18px;
  background-color: rgb(216, 46, 46);
  color: white;
  text-align: center;
  font-size: larger;
  font-size: 28px;
}

.parentContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem;
}

.boxContainer {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  justify-content: center;
}

.boxContent {
  background-color: #ffffff;
  border: 3px solid #e7e7e7;
  border-radius: 16px;
  margin: 1rem;
  min-width: 25%;
  max-width: 100%;
  padding: 20px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.boxText {
  font-size: 20px;
}

.boxNumbers {
  margin-top: 16px;
  font-size: 48px;
  font-weight: bold;
  color: #e74c3c;
}

.outerContainer {
  overflow-x: auto;
}

.horizontalparentContainer {
  display: flex;
  justify-content: flex-start;
  margin: 28px;
  flex-wrap: nowrap;
}

.horizontalBoxContent {
  background-color: #f9e5e7;
  padding: 24px;
  margin: 18px;
  border-radius: 12px;
  width: 100vh;
  min-width: 300px;
}

.horizontalBoxText {
  font-size: 24px;
  font-weight: 500;
  color: rgb(63, 62, 62);
}

.separator {
  font-weight: 100;
  color: #c2c2c2; /* Adjust the color as needed */
}

.lefthorizontalBar,
.righthorizontalBar {
  margin-top: 18px;
  padding: 6px 2px;
  border-radius: 18px;
  font-weight: 900;
  font-size: larger;
  color: white;
}

.newthree-year-div {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin: 20px 0;
  max-width: 100%;
}

.newyear-block1,
.newyear-block2,
.newyear-block3 {
  color: #fff;
  justify-content: center;
  font-weight: bold;
  height: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #f0f0f0;
}

.newyear-block1 {
  background-color: #3498db;
}

.newyear-block2 {
  background-color: #e74c3c;
}

.newyear-block3 {
  background-color: #2ecc71;
}

.NewStatsBarGraphContainer {
  margin: 20px;
}

.NewStatsBarGraph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statsSubTableText {
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
}

.subTableHeading {
  padding: 12px;
  margin-top: 24px;
  text-align: center;
  font-size: larger;
  font-size: 28px;
  font-weight: 600;
  color: rgb(16, 16, 16);
}

.parentStatisticsContainer {
  display: flex;
  margin-top: 32px;
  justify-content: center;
  gap: 1.5rem;
}

.statsTableText {
  padding: 12px;
  text-align: center;
  font-size: larger;
  font-size: 28px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
  margin-bottom: 8px; /* Adjust this margin as needed */
}

.statsInputParent {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px;
  border-radius: 8px;
  position: sticky;
  background-color: #ffffff;
  top: 65px;
}

.statsInputFeild {
  width: 300px;
}

.statsSubTableText {
  text-align: center;
  font-size: medium;
  font-size: 18px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 115px;
  background-color: #ffffff;
  z-index: 1;
  margin-bottom: 8px;
}

.couponContainer {
  width: 100%;
  background-color: #ffffff;
  border: 3px solid #e7e7e7;
  padding: 18px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  margin-bottom: 48px;
  max-height: 800px;
  overflow-y: auto;
  border-radius: 10px;
  position: relative;
}
.couponContainer::-webkit-scrollbar {
  display: none;
}

.couponStats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.couponStats h3 {
  margin: 0;
  font-size: 16px;
}

@media (max-width: 768px) {
  .mainContainer {
    margin-left: 20px;
    margin-right: 20px;
  }

  .navbarText {
    font-size: 20px;
    padding: 10px;
  }

  .parentContainer {
    flex-direction: column;
    margin: 0;
  }

  .boxContainer {
    flex-direction: column;
    align-items: center;
  }

  .boxContent {
    min-width: 100%;
    margin: 10px 0;
  }

  .boxText {
    font-size: 16px;
  }

  .boxNumbers {
    font-size: 32px;
  }

  .subTableHeading {
    font-size: 20px;
  }
  .outerContainer {
    overflow-x: hidden;
  }
  .horizontalBoxContent {
    width: auto;
  }
  .NewStatsBarGraphContainer {
    margin: 10px; /* Adjust margin as needed */
  }

  .NewStatsBarGraph {
    padding: 16px; /* Adjust padding as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .parentStatisticsContainer {
    flex-direction: column;
  }
}
