/* customTabs.css */

.ant-tabs-tab-active .ant-tabs-tab-btn {
    background-color:rgb(236 88 99)!important;
    font-weight: 700;
    border-radius: 6px;
    padding: 6px;
    color: white !important; /* Text color inside the active tab */
  }
  
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    border: none; /* Adjust border if needed */
  }

  .custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    border-bottom: 2px solid red !important; /* Change this to your desired color */
  }
  .ant-tabs .ant-tabs-nav .ant-tabs-tab {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
 