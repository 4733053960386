.MainDiv {
  background-color: white;
  width: 100%;
  height: 100vh;
  /*   background-image: url("../../StaticImages/DoodleBg2.svg"); */
}

.Container {
  width: 100%;
  padding-top: 3em;
  display: flex;
  justify-content: center;
}

.LoginBox {
  width: 550px;
  min-height: 750px;
  background-color: white;
  box-shadow: 0 0 30px 0 hsl(0deg 0% 57% / 22%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 4em;
  padding-right: 4em;
  padding-top: 3em;
  padding-bottom: 3em;
  border-radius: 10px;
  margin-left: -10rem;
  margin-right: 10rem;
}

.IconImage {
  height: 15em;
}

.LoginHeading {
  margin-top: -0.5em;
  margin-bottom: 0.5em;
  font-size: 3em;
  font-weight: 500;
  color: black;
}

.InputField {
  width: 100%;
}

.LoginButton {
  width: 100%;
  margin-bottom: 2em;
}

.ErrorAlert {
  width: 100%;
  margin-bottom: 1.5em;
  border-radius: 100px;
}
.instructions
{
  font-size: 1em;
  font-weight: 500;
  color: black;
  margin-bottom: 1em;
  border: 1px solid red;
  padding: 0.5rem;
  text-align: justify;
  border-radius: 0.2rem;
  background-color: rgba(245, 75, 75, 0.268);

}
@media only screen and (max-width: 700px) {
  .LoginBox {
    width: 100%;
    min-height: 0px;
    padding-left: 2em;
    padding-right: 2em;
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .Container {
    padding-top: 0em;
  }
  .MainDiv {
    background-color: white;
    width: 100%;
    min-height: fit-content;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
