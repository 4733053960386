.MarketingNav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: #ff4c5a;
    padding: 1rem;
    color: white;
    font-size: 1.25rem;
    border-right: 1px solid #e0e0e0;
  }
  
  #Table-HeadM {
    background-color: #ff4c5a;
    color: white !important;
  }
  
  #Table-Heading {
    background-color: #ff4c5a;
    color: white !important;
    font-size: 0.7rem;
  }
  
  #MarketingTable-Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  #MarketingTable {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    font-size: 0.9rem;
  }
  #MarketingTable2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    font-size: 0.9rem;
  }
  
  .YetAnotherButton {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .MBtn {
    background-color: #ff4c5a;
    color: white;
    border: 1px solid white;
  }
  
  .MBtn:hover {
    background-color: white !important;
    color: #ff4c5a !important;
    border: 1px solid #ff4c5a !important;
  }
  
  .radio-inputsM {
    position: relative;
    display: flex;
    border-radius: 0.5rem;
    border: 1px solid #ff4c5a !important;
    box-sizing: border-box;
    color: black;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
    font-size: 14px;
  }
  
  .radio-inputsM .radio {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .radio-inputsM .radio input {
    display: none;
  }
  
  .radio-inputsM .radio .name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: none;
    color: black;
    transition: all 0.15s ease-in-out;
    padding: 0.5rem;
    /* margin-bottom: -0.5rem; */
    font-weight: 600;
  }
  
  .radio-inputsM .radio input:checked + .name {
    background-color: #ff4c5a;
    font-weight: 600;
    color: white;
  }
  .MRadio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .MBtnDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MBtn2 {
    background-color: #ff4c5a;
    color: white;
    border: 1px solid white;
    width: fit-content;
    padding: 0.5rem;
    margin-left: 1rem;
    border-radius: 0.5rem;
    transition-duration: 1s;
  }
  .MBtn2:hover {
    background-color: white !important;
    color: #ff4c5a !important;
    border: 1px solid #ff4c5a !important;
  }
  #MarketingTable-Wrapper2 {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .MBtnDiv2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
    width: 88%;
  }
  .MBtn3 {
    background-color: #ff4c5a;
    color: white;
    border: 1px solid white;
    width: fit-content;
    padding: 0.3rem;
    border-radius: 0.5rem;
    transition-duration: 1s;
    width: 150%;
  }
  .MBtn3:hover {
    background-color: white !important;
    color: #ff4c5a !important;
    border: 1px solid #ff4c5a !important;
  }
  #NotContacted {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: gray;
    color: white;
  }
  #Responded-N {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: red;
    color: white;
  }
  #Responded-P {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: green;
    color: white;
  }
  #Follow-Up {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: yellow;
    color: white;
  }
  .MarketingBox {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
  }
  .MarketingBox1 {
    display: flex;
    border: 1px solid #ff4c5a;
    border-radius: 0.5rem;
    flex-direction: column;
    text-align: left;
    padding: 1rem;
    height: 100%;
    width: 100%;
    color: black;
    font-size: 1.25rem;
  }
  
  .MarketingBox1-Inner {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    text-decoration: none;
  }
  .MarketingBox1-Inner h6 {
    text-decoration: none;
  }
  .MarketingBox2 {
    display: flex;
    border: 1px solid #ff4c5a;
    border-radius: 0.5rem;
    flex-direction: column;
    text-align: left;
    padding: 1rem;
    height: 100%;
    width: 100%;
    color: black;
    font-size: 1.25rem;
  }
  .three-year-div {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    border: 1px solid black;
  }
  .MarketingDisplay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .year-block1 {
    background-color: yellow;
    text-align: center;
    font-weight: 400;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    font-family: "Nunito, sans-serif";
    margin-left: -1rem;
  }
  .year-block2 {
    background-color: coral;
    text-align: center;
    font-weight: 400;
    font-family: "Nunito, sans-serif";
  }
  .year-block3 {
    background-color: dodgerblue;
    text-align: center;
    font-weight: 400;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    font-family: "Nunito, sans-serif";
    margin-right: -1rem;
  }
  .MarketingNewWrapper {
    display: flex;
    border: 1px solid #ff4c5a;
    border-radius: 0.5rem;
    flex-direction: column;
    text-align: left;
    padding: 1rem;
    height: 100%;
    width: 100%;
    color: black;
    font-size: 1.25rem;
  }
  
  .StatsBarGraphContainer {
    margin: 20px auto;
  }
  
  .StatsBarGraph {
    display: flex;
    justify-content: center;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .StatsBarGraphTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: "Nunito", sans-serif;
  }
  
  /* Styling for the X and Y axis labels */
  .recharts-cartesian-axis-tick-value {
    font-size: 14px;
    fill: #333;
  }
  
  /* Styling for the tooltip content */
  .recharts-tooltip-item {
    color: #333;
    font-size: 14px;
  }
  
  /* Styling for the tooltip label */
  .recharts-tooltip-label {
    font-weight: bold;
  }
  
  /* Styling for the legend */
  .recharts-legend-wrapper {
    margin-top: 20px;
  }
  .MarketingNav-Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .MarketingTableNew {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    font-size: 0.9rem;
  }
  .ContainerWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .radio-wrapper {
    width: 88% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .Video-Btn {
    background-color: #ff4c5a !important;
    color: white !important;
    border: 1px solid white !important;
    width: fit-content;
    padding: 0.5rem;
    margin-left: 1rem;
    border-radius: 0.5rem;
    transition-duration: 1s;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .Video-Btn:hover {
    background-color: white !important;
    color: #ff4c5a !important;
    border: 1px solid #ff4c5a !important;
  }
  .video-description {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem !important;
    font-weight: 600;
    color: black;
  }
  
  .datePicker{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b3b3b3;
    margin-top: -1rem;
    margin-bottom: 1rem;
    border-radius: 0.3rem;
    padding: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
  }