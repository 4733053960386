
/* animation for box */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.ribbon {
    width: 130px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    /* border: 5px solid rgb(142, 79, 194); */
}

.ribbon span {
    position: absolute;
    display: block;
    width: 200px;
    padding: 1px 0;
    /* background: rgb(142, 79, 194); */
    /* background: #EC5863; */
    box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
    color: rgba(0, 0, 0, 0.411);
    font-weight: bold;
    font-size: 0.9rem;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
    justify-items: center;
    color: white;
}

.ribbonTopLeft {
    top: 0px;
    left: 0px;
}

.ribbonTopLeft::before,
.ribbonTopLeft::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbonTopLeft::before {
    top: 0;
    right: 0;
}

.ribbonTopLeft::after {
    bottom: 0;
    left: 0;
}

.ribbonTopLeft span {
    right: 0px;
    top: 17px;
    transform: rotate(-45deg);
}

